<template>
  <div class="world appointments-modal appointments-showroom">
    <div class="loader-steps"></div>
    <div class="loader-content"></div>
    <div class="content">
      <div class="steps">
        <div :key="page.index" v-for="page in pages" class="step" :class="{'active': page.active}">
          <div class="line"></div>
          <div class="label">{{ page.title }}</div>
        </div>
      </div>
      <swiper :options="swiperOptions" ref="swiper">
        <swiper-slide>
          <formulate-form ref="personalInformation" :debounce="100" name="personalInformation" v-model="formValues">
            <h2>{{ $t('Jouw gegevens') }}</h2>
            <div class="personalInformation">
              <formulate-input validation="required" error-behavior="submit" type="text" name="first_name" label="Voornaam"></formulate-input>
              <formulate-input validation="required" error-behavior="submit" type="text" name="last_name" label="Achternaam"></formulate-input>
              <formulate-input validation="required|email" error-behavior="submit" type="email" name="email" :label="$t('E-mail')"></formulate-input>
              <formulate-input validation="required" error-behavior="submit" type="tel" name="telephone" label="Telefoonnummer"></formulate-input>

              <formulate-input validation="required" error-behavior="submit" type="text" name="address1" label="Adres"></formulate-input>
              <formulate-input validation="required" error-behavior="submit" type="text" name="locality" label="Gemeente"></formulate-input>
              <formulate-input validation="required" class="sm" error-behavior="submit" type="text" name="postal_code" label="Postcode"></formulate-input>

              <formulate-input type="checkbox" name="is_same_invoice_address" :value="true" :checked="true" label="Facturatie adres is hetzelfde als leveringsadres"/>
              <div v-if="!formValues.is_same_invoice_address">
                <formulate-input validation="required" error-behavior="submit" type="text" name="invoice_address1" label="Adres"></formulate-input>
                <formulate-input validation="required" error-behavior="submit" type="text" name="invoice_locality" label="Gemeente"></formulate-input>
                <formulate-input validation="required" class="sm" error-behavior="submit" type="text" name="invoice_postal_code" label="Postcode"></formulate-input>
              </div>

              <formulate-input validation="required" error-behavior="submit" type="checkbox" name="privacy_policy">
                <template #label="input">
                  <label :for="input.id" :class="input.classes.label">
                    <span>Ik heb het</span>
                    <a target="_blank" href="https://wilms-web.ams3.cdn.digitaloceanspaces.com/production/assets/main/PrivacyVerklaringNL.pdf">privacybeleid</a>
                    <span>gelezen en ga hiermee akkoord.</span>
                  </label>
                </template>
              </formulate-input>
            </div>

            <div class="buttons">
              <div></div>
              <a class="btn" v-on:click="goToDateSelect()">{{ $t('Volgende') }}</a>
            </div>
          </formulate-form>
        </swiper-slide>

        <swiper-slide>
          <h2>{{ $t('Datum en tijd kiezen') }}</h2>

          <div class="date-time-wrapper">
            <div class="date-wrapper">
              <v-date-picker is-required :disabled-dates="{weekdays: 1}" title-position="left" v-model="selectedDate" @dayclick="onDayClick" :min-date="minDate"></v-date-picker>
            </div>
            <div class="time-wrapper">
              <h3 v-if="slots.length > 0">{{ $t('Beschikbare uren') }}</h3>
              <h3 v-if="slots.length == 0">{{ $t('Geen uren beschikbaar op deze datum') }}</h3>
              <div class="timeslots" v-if="slots.length > 0">
                <div :key="i" v-on:click="setSelectedSlot(slot)" class="timeslot" :class="{'available': slot.available, 'active': selectedSlot === slot}" v-for="(slot, i) in slots">
                  <span>{{ slot.time.setLocale('nl').toFormat('HH:mm') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="datetime-result" v-if="selectedSlot !== null">
            {{ $t('Jouw afspraak gaat door op') }}
            <span>{{ selectedSlot.time.setLocale('nl').toFormat('cccc d LLLL y') }} om {{ selectedSlot.time.setLocale('nl').toFormat('HH:mm') }}</span>
          </div>

          <div class="buttons">
            <a class="btn" v-on:click="goToPage(0)">{{ $t('Vorige') }}</a>
            <a v-if="selectedSlot !== null" class="btn" v-on:click="goToPage(2, 'extra_gegevens')">{{ $t('Volgende') }}</a>
          </div>
        </swiper-slide>

        <swiper-slide>
          <formulate-form ref="boost" name="boost" :debounce="100" v-model="formValues" @submit="goToConfirmation">
            <div class="boost">
              <div class="left">
                <formulate-input validation="required" error-behavior="submit" name="project_type" :options="projectTypeOptions" type="radio" label="Wat voor type project is het?"></formulate-input>
                <formulate-input validation="required" error-behavior="submit" name="searching_for" :options="searchingForOptions" type="checkbox" label="Ik ben op zoek naar"></formulate-input>
              </div>

              <div class="right">
                <formulate-input validation="required" error-behavior="submit" name="new_windows" :options="newWindowsOptions" type="radio" label="Worden er ook nieuwe ramen geplaatst?"></formulate-input>
                <formulate-input validation="required" error-behavior="submit" name="delivery_term" :options="deliveryTermOptions" type="radio" label="Wanneer moeten de werken uitgevoerd worden?"></formulate-input>
              </div>
            </div>

            <div class="buttons">
              <a class="btn" v-on:click="goToPage(1)">{{ $t('Vorige') }}</a>
              <formulate-input type="submit" :label="isLoading ? 'Even geduld...' : 'Volgende'"/>
            </div>
          </formulate-form>
        </swiper-slide>

        <swiper-slide>
          <div class="confirmation-wrapper">
            <h2>{{ $t('Bevestigen') }}</h2>

            <h3>{{ $t('Jouw gegevens') }}</h3>
            <div class="grid">
              <div>
                <div class="label">{{ $t('Voornaam') }}</div>
                <div class="value">{{ formValues.first_name }}</div>
              </div>
              <div>
                <div class="label">{{ $t('Achternaam') }}</div>
                <div class="value">{{ formValues.last_name }}</div>
              </div>
              <div>
                <div class="label">{{ $t('E-mailadres') }}</div>
                <div class="value">{{ formValues.email }}</div>
              </div>
              <div>
                <div class="label">{{ $t('Telefoonnnummer') }}</div>
                <div class="value">{{ formValues.telephone }}</div>
              </div>
            </div>

            <h3>{{ $t('Adresgegevens') }}</h3>

            <div class="grid">
              <div>
                <div class="label">{{ $t('Straat en huisnummer') }}</div>
                <div class="value">{{ formValues.address1 }}</div>
              </div>
              <div>
                <div class="label">{{ $t('Gemeente') }}</div>
                <div class="value">{{ formValues.locality }}</div>
              </div>
              <div>
                <div class="label">{{ $t('Postcode') }}</div>
                <div class="value">{{ formValues.postal_code }}</div>
              </div>
            </div>

            <h3>{{ $t('Facturatiegegevens') }}</h3>

            <div class="grid" v-if="formValues.is_same_invoice_address">
              <div>
                <div class="label">{{ $t('Straat en huisnummer') }}</div>
                <div class="value">{{ formValues.invoice_address1 }}</div>
              </div>
              <div>
                <div class="label">{{ $t('Gemeente') }}</div>
                <div class="value">{{ formValues.invoice_locality }}</div>
              </div>
              <div>
                <div class="label">{{ $t('Postcode') }}</div>
                <div class="value">{{ formValues.invoice_postal_code }}</div>
              </div>
            </div>

            <div v-else>Facturatie adres is hetzelfde als leveringsadres</div>

            <h3>{{ $t('Datum en tijd') }}</h3>
            <div class="grid">
              <div>
                <div class="label">{{ $t('Datum') }}</div>
                <div class="value" v-if="selectedSlot !== null">{{ selectedSlot.time.setLocale('nl').toFormat('d LLLL y') }}</div>
              </div>
              <div>
                <div class="label">{{ $t('Uur') }}</div>
                <div class="value" v-if="selectedSlot !== null">{{ selectedSlot.time.setLocale('nl').toFormat('HH:mm') }}</div>
              </div>
            </div>

            <h3>{{ $t('Geef je afspraak een startboost') }}</h3>
            <div class="grid">
              <div>
                <div class="label">{{ $t('Type project') }}</div>
                <div class="value">{{ projectTypeOptions[formValues.project_type] }}</div>
              </div>
              <div>
                <div class="label">{{ $t('Ik ben op zoek naar') }}</div>

                <div class="value">
                  <span :key="i" v-for="(label, i) in formValues.searching_for">
                    {{ searchingForOptions[label] }}
                    {{ (formValues.searching_for.length > (i + 1)) ? ',' : '' }}
                  </span>
                </div>
              </div>
              <div>
                <div class="label">{{ $t('Nieuwe ramen') }}</div>
                <div class="value">{{ newWindowsOptions[formValues.new_windows] }}</div>
              </div>
              <div>
                <div class="label">{{ $t('Geschatte periode') }}</div>
                <div class="value">{{ deliveryTermOptions[formValues.delivery_term] }}</div>
              </div>
            </div>
          </div>

          <div class="buttons">
            <a class="btn" v-on:click="goToPage(2)">{{ $t('Vorige') }}</a>
            <formulate-form ref="confirmation" name="confirmation" @submit="submit" v-model="formValues">
              <formulate-input type="submit" :label="isLoading ? 'Even geduld...' : 'Bevestigen'"/>
            </formulate-form>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="confirmation-message">
            <h2>Bedankt voor je afspraak!</h2>
            <p>Je gegevens werden correct geregistreerd, dankjewel voor je bezoek aan Wilms.</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import { DatePicker } from 'v-calendar'
import { DateTime } from 'luxon'
import { ApiService } from '@/services/admin/api.service'
import appointmentsData from '@/appointments-data'
// import axios from 'axios'
// import { sha256 } from 'js-sha256'

export default {
  mixins: [appointmentsData],
  components: {
    Swiper,
    SwiperSlide,
    'v-date-picker': DatePicker
  },
  directives: {
    swiper: directive
  },
  data () {
    return {
      swiperOptions: {
        allowTouchMove: false,
        autoHeight: true,
        simulateTouch: false
      },
      uploader: ApiService.getAxiosInstance(),
      uploadUrl: '/appointments/upload/',
      pages: [],
      isLoading: false,
      formValues: {
        privacy_policy: true
      },
      selectedDate: null,
      selectedSlot: null,
      selectedDateTime: null,
      slots: [],
      firstSlot: null,
      lastSlot: null,
      minDate: new Date(),
      files: []
    }
  },
  mounted () {
    this.isLoading = true
    this.minDate.setDate(this.minDate.getDate())

    document.body.classList.add('ui')
    document.body.classList.add('no-ui')

    setInterval(() => {
      window.dispatchEvent(new Event('resize'))
    }, 1500)

    this.pages = [
      {
        index: 0,
        title: this.$t('Jouw gegevens'),
        active: true
      },
      {
        index: 1,
        title: this.$t('Datum en tijd kiezen'),
        active: false
      },
      {
        index: 2,
        title: this.$t('Geef je afspraak een startboost'),
        active: false
      },
      {
        index: 3,
        title: this.$t('Bevestigen'),
        active: false
      }
    ]

    window.parent.postMessage({ message: 'ga_event_sent', event: 'modal_opened' }, '*')

    this.isLoading = false
  },
  methods: {
    goToDateSelect: async function () {
      this.$formulate.submit('personalInformation')
      const hasErrors = await this.$refs.personalInformation.hasValidationErrors()
      window.dispatchEvent(new Event('resize'))

      window.parent.postMessage({ message: 'ga_event_sent', event: 'datum_en_tijd' }, '*')

      if (!hasErrors) {
        this.goToPage(1)
      }
    },
    goToConfirmation: function (formData) {
      if (formData.files) {
        this.files = this.flattenFormFileIds(formData)
        // this.files = formData.files
      }

      window.parent.postMessage({ message: 'ga_event_sent', event: 'bekijk_overzicht' }, '*')

      window.dispatchEvent(new Event('resize'))
      this.goToPage(3)
    },
    fetchReservedSlots: async function () {
      const response = await ApiService.fetchReservedSlots(this.selectedDate, this.selectedDate, false, true)
        .catch(() => {
          this.message = this.$t('Something went wrong, please try again later...')
          this.loading = false
        })

      if (response.data.slots !== undefined) {
        this.slots = response.data.slots[this.selectedDate]

        this.slots.forEach((slot, i) => {
          this.slots[i].time = DateTime.fromISO(slot.time)
          this.slots[i].available = slot.available
        })
      }
    },
    /**
     * Submit the actual form.
     *
     * @param {*} formData
     */
    submit: async function (formData) {
      this.isLoading = true
      const appointment = { ...this.formValues, ...{ slot: this.selectedDateTime } }
      appointment.spontaneous = true

      if (this.files.length > 0) {
        appointment.files = this.files
      }

      const response = await ApiService.saveAppointment(appointment, 'post', true)
        .catch(() => {
          this.message = this.$t('Something went wrong, please try again later...')
          this.loading = false
        })

      if (response.status === 200) {
        window.parent.postMessage({ message: 'ga_event_sent', event: 'submit_afspraak', email: appointment.email }, '*')

        this.$formulate.reset('personalInformation')
        this.$formulate.reset('boost')
        this.$formulate.reset('confirmation')
        this.selectedDate = null
        this.selectedSlot = null
        this.selectedDateTime = null
        this.goToPage(4)
        this.isLoading = false

        setTimeout(() => {
          location.reload()
        }, 5000)
      }
    },
    /**
     *
     * Not the cleanest, but we expect a list of integers in the
     * files POST value, thus we need to flatten the returned
     * list single INT lists into a simple list of INTs.
     *
     * @param {*} formData
     * @returns List[int]
     */
    flattenFormFileIds: function (formData) {
      const flattened = []
      formData.files.forEach((fileId) => {
        flattened.push(fileId[0])
      })
      return flattened
    },
    /**
     * Handler when clicking on a day in the VCalendar.
     *
     * @param {*} day
     */
    onDayClick: async function (day) {
      if (day.isDisabled) {
        return
      }

      this.selectedDate = day.id
      this.firstSlot = DateTime.fromISO(''.concat(this.selectedDate, 'T', '08:00:00'))
      this.lastSlot = DateTime.fromISO(''.concat(this.selectedDate, 'T', '16:20:00'))
      await this.fetchReservedSlots()
    },
    setSelectedSlot: function (slot) {
      if (!slot.available) {
        return
      }

      this.selectedSlot = slot

      this.selectedDateTime = ''.concat(this.selectedDate, 'T', this.selectedSlot.time.toISOTime({ includeOffset: false }))
    },
    goToPage: function (_index, event = null) {
      this.pages.forEach(p => {
        p.active = p.index <= _index
      })

      if (event) {
        window.parent.postMessage({ message: 'ga_event_sent', event: event }, '*')
      }

      // Reset any generated message.
      this.message = null

      this.$refs.swiper.$swiper.slideTo(_index)
    }
  },
  computed: {
    dates: function () {
      return this.days.map(day => day.date)
    },
    attributes: function () {
      return this.dates.map(date => ({
        highlight: true,
        dates: date
      }))
    }
  }
}

</script>
